/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Custom change */

// @font-face {
//     font-family: 'gotham-bold';
//     src: url('../src/assets/fonts/Gotham-bold.ttf');
// }
// @font-face {
//     font-family: 'gotham-book';
//     src: url('../src/assets/fonts/gotham-book-webfont.ttf');
// }
// @font-face {
//     font-family: 'gotham-extralight';
//     src: url('../src/assets/fonts/gotham-extralight.ttf');
// }
body {
    font-size: 14px;
}

ion-content {
    width: 100%;
}

@media screen and (max-width: 768px) {
    ion-content {
        max-width: 500px;
    }
}

@media screen and (min-width: 768px) {
    .btn {
        max-width: 800px;
        margin: 30px auto;
    }
    .header.desktop ul {
        justify-content: center !important;
    }
}

.header.desktop {
    background-image: linear-gradient(to right, #1E51A9, #2564D1);
    width: 100%;
    padding: 20px 30px;
    margin-bottom: 0 !important;
    position: relative;
    overflow: visible;
    z-index: 10;
    color: #FFF;
    &::after {
        content: "Investor Portal";
        position: absolute;
        bottom: 0px;
        padding: 6px 60px;
        left: 30px;
        transform: translateY(50%);
        border-radius: 50px;
        font-size: 18px;
        font-weight: 800;
        background-image: linear-gradient(to right, #EA7600, #CE0F69);
    }
    img {
        max-height: 50px;
    }
    ion-icon {
        font-size: 24px;
        color: white !important;
    }
    .profile-btn__container {
        cursor: pointer;
    }
    .profile-name {
        padding: 0 30px 0 10px;
    }
    ul {
        display: flex;
        justify-content: center;
        position: relative;
        width: 700px;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        li {
            padding: 0 30px;
            position: relative;
            cursor: pointer;
        }
        li.active {
            font-weight: 800;
            &::after {
                content: "";
                position: absolute;
                bottom: -26px;
                width: 80%;
                left: 50%;
                transform: translateX(-50%);
                height: 6px;
                background-image: linear-gradient(to right, #EA7600, #CE0F69);
            }
        }
    }
}

ion-content {
    --padding-top: 16px;
    --padding-bottom: 16px;
}

ion-button {
    text-transform: none;
}

* {
    // font-family: 'gotham-book';
    // letter-spacing: -0.5px;
    box-sizing: border-box;
}

.ion-page {
    align-items: center;
    background-color: #ffffff;
}

.header-toolbar {
    --border-width: 0px;
    --border-style: none;
    --background: transparent;
    --color: #ffffff;
    ion-title {
        font-size: 1.7rem;
    }
}

.header-toolbar-basic {
    --padding-top: 50px;
    --padding-bottom: 50px;
    img {
        margin: 0 auto;
    }
}

.btn {
    padding: 0px 16px;
}

.btn-empty {
    --border-color: var(--ion-color-primary);
    --border-style: solid;
    --border-width: 1px;
    min-height: 50px;
    display: block;
}

.btn-color-white {
    --color: #ffffff;
}

.btn-gradient {
    --background: linear-gradient(to right, #C80F6B 0%, #1B329F 100%);
    --color: #ffffff;
    --border-style: none;
    --border-radius: 10px;
    --padding-top: 24px;
    --padding-bottom: 24px;
    margin: 4px 16px;
    font-size: 18px;
}

.btn-primary-light {
    --background: #E6EDFA;
    --color: var(--ion-color-primary);
    --border-style: none;
    --border-radius: 10px;
    --padding-top: 24px;
    --padding-bottom: 24px;
    margin: 0px 16px;
    font-size: 18px;
}

.btn-div{
    text-align: center;
    border-radius: 10px;
    border-color: var(--ion-color-primary);
    border-style: solid;
    border-width: 1px;
    min-height: 50px;
    display: block;
    font-size: 18px;
    color: var(--ion-color-primary);
    margin: 4px 16px;
    padding: 16px 8px;
}

.btn-back{
    font-size: 1.2rem;

    .btn-back__icon{
        font-size: 1.9rem;
    }
}

.text-left {
    text-align: left;
}

.center {
    text-align: center;
}

.mb-30 {
    margin-bottom: 30px;
}

.mh-100 {
    min-height: 100%;
}

// FLEX
.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.home-modal .modal-wrapper {
    height: 60%;
    bottom: 0;
    position: absolute;
    border-radius: 5px 5px 0px 0px;
    .header-toolbar__icon {
        font-size: 30px;
        margin-left: 8px;
    }
}

.home-modal.event .modal-wrapper {
    height: 80% !important;
}

.width-100 {
    width: 100%;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.ml-1 {
    margin-left: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 0.75rem;
}

.ml-4 {
    margin-left: 1rem;
}

.mt-1 {
    margin-top: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-3 {
    margin-top: 0.75rem;
}

.mt-4 {
    margin-top: 1rem;
}

.mt-8 {
    margin-top: 2rem;
}

.only-mobile,
.only-mobile-flex {
    display: none !important;
}

.only-desktop {
    display: block !important;
}

.only-desktop-flex {
    display: flex !important;
}

@media screen and (max-width: 768px) {
    .only-mobile {
        display: block !important;
    }
    .only-mobile-flex {
        display: flex !important;
    }
    .only-desktop,
    .only-desktop-flex {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    .btn-div{
        margin: 4px auto;
    }
}

.toggle{
    --background: rgba(255, 255, 255, 0.5);
}

.search-desktop{
    max-width: 400px;
    margin: 0 auto;
    padding: 0;
}